import { LocalDate, LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'

export const TimesheetFunctionsMixin = {
  data() {
    return {
      dates: {
        today: LocalDate.today(),
        currentWeekFirst: LocalDate.mondayInCurrentWeek(),
        currentWeekLast: LocalDate.fridayInCurrentWeek(),
        currentMonth: LocalDate.currentMonth(),
        currentMonthFirst: LocalDate.firstDayOfCurrentMonth(),
        currentMonthLast: LocalDate.lastDayOfCurrentMonth(),
        currentYear: LocalDate.currentYear(),
        currentYearFirst: LocalDate.currentYear() + '-01-01',
        currentYearLast: LocalDate.currentYear() + '-12-31',
        lastYear: LocalDate.lastYear()
      }
    }
  },
  methods: {
    workingHoursSum(target, actual) {
      const targetWorkingMinutes = target
      const actualWorkingMinutes = actual
      const overtime = actualWorkingMinutes > targetWorkingMinutes
      if (actualWorkingMinutes === targetWorkingMinutes) {
        return LocalDateTimeFormatter.durationInMinutesToTime(0)
      } else if (overtime) {
        return '+' + LocalDateTimeFormatter.durationInMinutesToTime(actualWorkingMinutes - targetWorkingMinutes)
      } else {
        return '-' + LocalDateTimeFormatter.durationInMinutesToTime(targetWorkingMinutes - actualWorkingMinutes)
      }
    },
    formatMinutes(minutes) {
      return LocalDateTimeFormatter.durationInMinutesToTime(minutes)
    },
    formatDate(date) {
      return LocalDateTimeFormatter.formatLocaleDate(date)
    },
    colorFormatHours(hoursString) {
      return hoursString.startsWith('-') ? 'text-danger' : 'text-success'
    },
    colorFormatMinutes(minutes) {
      return minutes < 0 ? 'text-danger' : 'text-success'
    }
  }
}
