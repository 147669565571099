<template>
  <b-form-datepicker
    :id="id"
    :value="value"
    v-on:input="updateValue($event)"
    :placeholder="placeholder"
    :locale="locale"
    v-bind="computedLabels || {}"
    :start-weekday="weekday"
    :date-format-options="dateFormatOptions"
    :hide-header="true"
    :reset-button="resetButton"
    :label-reset-button="this.$t('components.datepicker.labelResetButton')"
    :today-button="todayButton"
    :label-today-button="this.$t('components.datepicker.labelTodayButton')"
    :state="state"
    :min="min"
    :max="max"
    :button-only="buttonOnly"
    :disabled="disabled"
    :size="size"
    :date-disabled-fn="dateDisabled"
  >
  </b-form-datepicker>
</template>
<script>
export default {
  name: 'GoldflamDatepicker',
  props: {
    id: {
      required: false,
      type: String,
      default: 'datePicker'
    },
    locale: {
      required: true,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    dateFormatOptions: {
      required: false,
      type: Object,
      default() {
        return { year: 'numeric', month: 'numeric', day: 'numeric' }
      }
    },
    resetButton: {
      required: false,
      type: Boolean,
      default: true
    },
    todayButton: {
      required: false,
      type: Boolean,
      default: true
    },
    value: {
      required: false,
      type: String || Date,
      default: ''
    },
    state: {
      required: false,
      type: Boolean,
      default: null
    },
    min: {
      required: false,
      type: String || Date,
      default: null
    },
    max: {
      required: false,
      type: String || Date,
      default: null
    },
    buttonOnly: {
      required: false,
      type: Boolean,
      default: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    size: {
      required: false,
      type: String,
      default: 'md'
    },
    disableDatesBefore: {
      required: false,
      type: String,
      default: null
    },
    disableDatesAfter: {
      required: false,
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      weekday: 1
    }
  },
  computed: {
    computedLabels() {
      let label = {}
      label[this.locale] = {
        labelPrevDecade: this.$t('components.datepicker.labelPrevDecade'),
        labelPrevYear: this.$t('components.datepicker.labelPrevYear'),
        labelPrevMonth: this.$t('components.datepicker.labelPrevMonth'),
        labelCurrentMonth: this.$t('components.datepicker.labelCurrentMonth'),
        labelNextMonth: this.$t('components.datepicker.labelNextMonth'),
        labelNextYear: this.$t('components.datepicker.labelNextYear'),
        labelNextDecade: this.$t('components.datepicker.labelNextDecade'),
        labelToday: this.$t('components.datepicker.labelToday'),
        labelSelected: this.$t('components.datepicker.labelSelected'),
        labelNoDateSelected: this.$t('components.datepicker.labelNoDateSelected'),
        labelCalendar: this.$t('components.datepicker.labelCalendar'),
        labelNav: this.$t('components.datepicker.labelNav'),
        labelHelp: this.$t('components.datepicker.labelHelp')
      }
      return label[this.locale]
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },
    dateDisabled(ymd, date) {
      let borderDate
      if (this.disableDatesBefore !== null) {
        borderDate = new Date(this.disableDatesBefore)
        return date < borderDate && date.getDate() !== borderDate.getDate()
      } else if (this.disableDatesAfter !== null) {
        borderDate = new Date(this.disableDatesAfter)
        return date > borderDate && date.getDate() !== borderDate.getDate()
      }
    }
  }
}
</script>
