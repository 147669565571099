<template>
  <b-card-group deck class="mb-5">
    <TimesheetOverviewCard
      :user-id="userId"
      :card-header="$t('datetime.day.today')"
      :timespan-start="dates.today"
      :timespan-end="dates.today"
    />
    <TimesheetOverviewCard
      :user-id="userId"
      :card-header="$t('datetime.week.current')"
      :timespan-start="dates.currentWeekFirst"
      :timespan-end="dates.currentWeekLast"
    />
    <TimesheetOverviewCard
      :user-id="userId"
      :card-header="$t('datetime.month.current')"
      :timespan-start="dates.currentMonthFirst"
      :timespan-end="dates.today"
      :show-info-tooltips="true"
    />
  </b-card-group>
</template>

<script>
import { TimesheetFunctionsMixin } from '@/components/timesheet/TimesheetFunctionsMixin'
import TimesheetOverviewCard from '@/components/timesheet/TimesheetOverviewCard'

export default {
  name: 'TimesheetOverview',
  components: { TimesheetOverviewCard },
  mixins: [TimesheetFunctionsMixin],
  props: {
    userId: {
      required: true,
      type: Number
    }
  }
}
</script>
