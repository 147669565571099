<script>
import { HorizontalBar } from 'vue-chartjs'
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'

export default {
  extends: HorizontalBar,
  name: 'MonthlyWorkingHoursChart',
  props: {
    chartData: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        animation: {
          duration: 300
        },
        tooltips: {
          position: 'average',
          titleMarginBottom: 8,
          // Custom callbacks to overwrite certain tooltip properties
          // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#tooltip-callbacks
          callbacks: {
            // custom 'label' callback to format decimal hours human readable (7.2 -> 7:12)
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || ''
              const minutes = Math.round(tooltipItem.value * 60)
              const formattedVal = LocalDateTimeFormatter.durationInMinutesToTime(minutes).replace(/^0/, '')
              return ` ${label}: ${formattedVal}`
            },
            // custom 'title' callback to show the formatted date instead of the tick value (2021-10-15 -> 15.10.2021)
            title: function (tooltipItems) {
              return tooltipItems[0].yLabel.formattedDate
            }
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              ticks: {
                // don't skip and collapse ticks, always show all ticks
                autoSkip: false,
                // transparent ticks because we want to overwrite it with our 'tickLabelFormatterPlugin'
                fontColor: 'transparent',
                // Custom ticks callback to show a specific prop from our labels object
                // https://www.chartjs.org/docs/2.9.4/axes/labelling.html#creating-custom-tick-formats
                callback: function (value) {
                  return value.localizedDay
                }
              }
            }
          ]
        }
      },
      // A custom plugin to format tick labels based on their weekday, on a user's holiday or absence
      // and for displaying an absence/holiday description in the chart area
      // Docs: https://www.chartjs.org/docs/2.9.4/developers/plugins.html#plugin-core-api
      // Based on: https://stackoverflow.com/a/61974056/1128689
      tickLabelFormatterPlugin: {
        afterDraw: chart => {
          const ctx = chart.chart.ctx
          const yAxis = chart.scales['y-axis-0']
          ctx.save()
          chart.data.labels.forEach(l => {
            // Redraw y axis tick labels color coded
            const label = l.localizedDay
            // Default 'Tick Configuration' as documented on https://www.chartjs.org/docs/2.9.4/axes/styling.html#tick-configuration
            // Properties are HTML5 Canvas API props (https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API)
            ctx.textAlign = 'right'
            ctx.font = "normal 12px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
            ctx.fillStyle = '#666'
            // Override fillStyle color
            if (l.isHoliday) {
              // Bootstrap 4 'primary'
              ctx.fillStyle = '#007bff'
            } else if (l.isWeekend) {
              // Bootstrap 4 'danger'
              ctx.fillStyle = '#dc3545'
            }

            const labelPositionY = yAxis.getPixelForValue(l) + 4
            const labelPositionX = yAxis.top + 37
            ctx.fillText(label, labelPositionX, labelPositionY)

            // Draw absence/holiday labels in chart area
            const descriptionPositionX = yAxis.top + 52
            if (l.isAbsence || l.isHoliday) {
              const description = l.isHoliday ? l.holidayLabel : l.absenceLabel
              ctx.textAlign = 'left'
              // draw a text stroke/background where the description is placed on a bar
              if (l.hasWorkedHours || l.isWorkday) {
                ctx.strokeStyle = '#f8f9fa'
                ctx.lineWidth = 6
                ctx.strokeText(description, descriptionPositionX, labelPositionY)
              }
              // don't color description text on weekends, except it's a holiday
              if (!l.isWorkday && !l.isHoliday) {
                ctx.fillStyle = '#666'
              }
              ctx.fillText(description, descriptionPositionX, labelPositionY)
            }
          })
          ctx.restore()
        }
      }
    }
  },
  mounted() {
    this.addPlugin(this.tickLabelFormatterPlugin)
    this.renderChart(this.chartData, this.options)
  }
}
</script>
