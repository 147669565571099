<template>
  <b-card bg-variant="light" class="text-center mb-5">
    <b-container fluid id="headContainer" class="p-0 mb-3">
      <b-row align-h="between" align-v="end">
        <b-col cols="4" class="text-right">
          <table class="ml-2">
            <tr>
              <td>{{ $t('datetime.timespan') }}:</td>
              <td colspan="2" class="pl-2">
                {{ formatDate(monthData.start).slice(0, 3) }}-{{ formatDate(monthData.end).slice(0, -4) }}
              </td>
              <td colspan="2" class="pl-4" v-if="filterIsCurrentMonth">
                {{ formatDate(monthData.start).slice(0, 3) }}-{{ formatDate(dates.today).slice(0, -4) }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('timesheet.target-working-hours') }}:</td>
              <td class="pl-2">
                <strong>{{ formatMinutes(monthData.targetWorkingMinutes) }}</strong>
              </td>
              <td class="pl-1">
                <span v-b-tooltip.right :title="$t('timesheet.tooltips.target-working-hours')">
                  <b-icon icon="question-circle" variant="secondary" />
                </span>
              </td>
              <td class="pl-4" v-if="filterIsCurrentMonth">
                <strong>{{ formatMinutes(monthData.targetWorkingMinutesUntilInclToday) }}</strong>
              </td>
              <td class="pl-1" v-if="filterIsCurrentMonth">
                <span v-b-tooltip.right :title="$t('timesheet.tooltips.target-working-hours')">
                  <b-icon icon="question-circle" variant="secondary" />
                </span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('timesheet.actual-working-hours') }}:</td>
              <td class="pl-2">
                <strong>{{ formatMinutes(monthData.actualWorkingMinutes) }}</strong>
              </td>
              <td class="pl-1">
                <span v-b-tooltip.right :title="$t('timesheet.tooltips.actual-working-hours')">
                  <b-icon icon="question-circle" variant="secondary" />
                </span>
              </td>
              <td class="pl-4" v-if="filterIsCurrentMonth">
                <strong>{{ formatMinutes(monthData.actualWorkingMinutes) }}</strong>
              </td>
              <td class="pl-1" v-if="filterIsCurrentMonth">
                <span v-b-tooltip.right :title="$t('timesheet.tooltips.actual-working-hours')">
                  <b-icon icon="question-circle" variant="secondary" />
                </span>
              </td>
            </tr>
            <tr>
              <td>{{ labelForRemainingHoursOrFlextime }}:</td>
              <td class="pl-2">
                <strong v-if="filterIsCurrentMonth">{{ formatMinutes(monthData.remainingWorkingMinutes) }}</strong>
                <strong v-else :class="[colorFormatHours(monthlyWorkingHoursSum)]">{{ monthlyWorkingHoursSum }}</strong>
              </td>
              <td class="pl-1">
                <span v-b-tooltip.right :title="$t('timesheet.tooltips.remaining-working-hours')">
                  <b-icon icon="question-circle" variant="secondary" />
                </span>
              </td>
              <td class="pl-4" v-if="filterIsCurrentMonth">
                <strong :class="[colorFormatHours(monthlyWorkingHoursSumUntilInclToday)]">{{
                  monthlyWorkingHoursSumUntilInclToday
                }}</strong>
              </td>
              <td class="pl-1" v-if="filterIsCurrentMonth">
                <span v-b-tooltip.right :title="$t('timesheet.tooltips.overtime-month')">
                  <b-icon icon="question-circle" variant="secondary" />
                </span>
              </td>
            </tr>
          </table>
        </b-col>
        <b-col />
        <b-col cols="2">
          <b-select :options="yearOptions" v-model="filter.year" @change="loadWorkdayDetails()" />
        </b-col>
        <b-col cols="2">
          <b-select :options="monthOptions" v-model="filter.month" @change="loadWorkdayDetails()" />
        </b-col>
      </b-row>
    </b-container>
    <!-- use this with a 'key' instead of proprietary 'reactiveProp' mixin to reload the chart when the timesheet data changes or locale changes -->
    <MonthlyWorkingHoursChart
      v-if="workdayDetailList.length > 0"
      :chart-data="timesheetData"
      :styles="{ height: '600px' }"
      :key="timesheetDataChanged"
    />
    <b-alert v-else variant="secondary" show class="py-5">
      <b-icon icon="graph-up" font-scale="7.5" variant="light" class="my-5" />
      <p>{{ $t('timesheet.chart.no-data-for-timespan') }}</p>
    </b-alert>
  </b-card>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import MonthlyWorkingHoursChart from '@/components/charts/MonthlyWorkingHoursChart'
import { LocalDate, LocalDateTimeFormatter, StaticDates } from '@/util/LocalDateTimeFormatter'
import dayjs from 'dayjs'
import { TimesheetFunctionsMixin } from '@/components/timesheet/TimesheetFunctionsMixin'

export default {
  name: 'TimesheetMonthChart',
  components: { MonthlyWorkingHoursChart },
  mixins: [ApiMixin, NotificationMixin, TimesheetFunctionsMixin],
  props: {
    userId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      yearOptions: [],
      monthOptions: StaticDates.monthListAsSelectArray(),
      filter: {
        year: null,
        month: null
      },
      timesheetDataChanged: null,
      workdayDetailList: [],
      chartOptions: {
        colors: {
          // Bootstrap 4 'warning'
          targetHours: 'rgba(255,193,7,0.9)',
          targetHoursFutureDate: 'rgba(0, 0, 0, 0.2)',
          // Bootstrap 4 'success'
          actualHours: 'rgba(40, 167, 69, 0.8)'
        },
        barBorderWidth: 0,
        barThickness: 10
      },
      chartData: {
        labels: [],
        targetWorkingHours: [],
        targetWorkingHoursBarBackgrounds: [],
        actualWorkingHours: []
      },
      monthData: {
        targetWorkingMinutes: 0,
        targetWorkingMinutesUntilInclToday: 0,
        actualWorkingMinutes: 0,
        remainingWorkingMinutes: 0,
        start: LocalDate.firstDayOfCurrentMonth(),
        end: LocalDate.lastDayOfCurrentMonth()
      }
    }
  },
  watch: {
    '$store.state.locale': function () {
      // reload on language change because response contains translated holiday descriptions
      this.loadWorkdayDetails()
    }
  },
  created() {
    this.yearOptions = StaticDates.yearListSinceAsArray(this.$store.getters.getConfig.APP_FIRST_YEAR)
    this.filter.year = this.dates.currentYear
    this.filter.month = this.dates.currentMonth
    this.loadWorkdayDetails()
  },
  computed: {
    timesheetData() {
      return {
        labels: this.chartData.labels,
        datasets: [
          {
            label: this.$t('timesheet.actual-working-hours'),
            data: this.chartData.actualWorkingHours,
            backgroundColor: this.chartOptions.colors.actualHours,
            borderWidth: this.chartOptions.barBorderWidth,
            barThickness: this.chartOptions.barThickness
          },
          {
            label: this.$t('timesheet.target-working-hours'),
            data: this.chartData.targetWorkingHours,
            backgroundColor: this.chartData.targetWorkingHoursBarBackgrounds,
            borderWidth: this.chartOptions.barBorderWidth,
            barThickness: this.chartOptions.barThickness
          }
        ]
      }
    },
    filterIsCurrentMonth() {
      return this.filter.month === this.dates.currentMonth && this.filter.year === this.dates.currentYear
    },
    filterIsFutureMonth() {
      return this.filter.month > this.dates.currentMonth || this.filter.year > this.dates.currentYear
    },
    labelForRemainingHoursOrFlextime() {
      if (this.filterIsCurrentMonth || this.filterIsFutureMonth) {
        return this.$t('timesheet.remaining-working-hours')
      } else if (this.monthlyWorkingHoursSum.startsWith('-')) {
        return this.$t('timesheet.flextime-account-minus')
      } else {
        return this.$t('timesheet.flextime-account-plus')
      }
    },
    monthlyWorkingHoursSum() {
      return this.workingHoursSum(this.monthData.targetWorkingMinutes, this.monthData.actualWorkingMinutes)
    },
    monthlyWorkingHoursSumUntilInclToday() {
      return this.workingHoursSum(
        this.monthData.targetWorkingMinutesUntilInclToday,
        this.monthData.actualWorkingMinutes
      )
    }
  },
  methods: {
    resetChartData() {
      this.chartData.labels = []
      this.chartData.targetWorkingHours = []
      this.chartData.targetWorkingHoursBarBackgrounds = []
      this.chartData.actualWorkingHours = []
    },
    resetMonthData() {
      this.monthData.targetWorkingMinutes = 0
      this.monthData.targetWorkingMinutesUntilInclToday = 0
      this.monthData.actualWorkingMinutes = 0
      this.monthData.remainingWorkingMinutes = 0
    },
    loadWorkdayDetails() {
      let self = this
      self.monthData.start = LocalDateTimeFormatter.toDate(this.filter.year + '-' + this.filter.month + '-01')
      self.monthData.end = LocalDate.lastDayOfMonth(this.filter.year, this.filter.month)
      this.getRequest(
        `/users/${self.userId}/timesheet/workday_details?start=${self.monthData.start}&end=${self.monthData.end}`,
        new RequestConfig().onSuccess(res => {
          self.workdayDetailList = res.data
          // reset chart and month data
          self.resetChartData()
          self.resetMonthData()
          // fill chart data arrays
          self.workdayDetailList.forEach(day => {
            const dayIsAfterToday = LocalDate.todayAsDayjs().isBefore(dayjs(day.date))
            const dayIsHoliday = day.holidays.length > 0
            const dayIsAbsence = day.absences.length > 0
            const absenceLabel = dayIsAbsence
              ? day.absences
                  .map(a => {
                    let lbl = this.$t('absence.types.' + a.type.toLowerCase())
                    if (a.endHalfDay && !a.startHalfDay && day.date === a.end) {
                      lbl = lbl + ' (' + this.$t('timesheet.chart.morning-only') + ')'
                    } else if (!a.endHalfDay && a.startHalfDay && day.date === a.start) {
                      lbl = lbl + ' (' + this.$t('timesheet.chart.afternoon-only') + ')'
                    }
                    return lbl
                  })
                  .join(', ')
              : ''

            // Create a huge label object instead of a single 'date' array, because this is the information needed
            // to color code the tick labels on the y axis based on weekday, absence, holiday, etc.
            // This is the only possibility to pass data into an chart callback or drawing plugin.
            const labelObject = {
              date: day.date,
              formattedDate: LocalDateTimeFormatter.formatLocaleDate(day.date),
              localizedDay: LocalDateTimeFormatter.toLocalizedDay(day.date),
              isWeekend: LocalDateTimeFormatter.isWeekendDay(day.date),
              isHoliday: dayIsHoliday,
              holidayLabel: dayIsHoliday ? day.holidays[0].description : '',
              isAbsence: dayIsAbsence,
              absenceLabel: absenceLabel,
              isWorkday: day.targetWorkingMinutes > 0,
              hasWorkedHours: day.actualWorkingMinutes > 0
            }

            // push data to chart data arrays
            self.chartData.labels.push(labelObject)
            self.chartData.targetWorkingHours.push(day.targetWorkingMinutes / 60)
            self.monthData.targetWorkingMinutes += day.targetWorkingMinutes
            self.monthData.targetWorkingMinutesUntilInclToday += dayIsAfterToday ? 0 : day.targetWorkingMinutes
            self.chartData.targetWorkingHoursBarBackgrounds.push(
              dayIsAfterToday ? self.chartOptions.colors.targetHoursFutureDate : self.chartOptions.colors.targetHours
            )
            self.chartData.actualWorkingHours.push(day.actualWorkingMinutes / 60)
            self.monthData.actualWorkingMinutes += day.actualWorkingMinutes
            self.monthData.remainingWorkingMinutes += dayIsAfterToday ? day.targetWorkingMinutes : 0
            self.timesheetDataChanged = LocalDate.getISOTimestamp()
          })
        })
      )
    }
  }
}
</script>
