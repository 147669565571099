<template>
  <b-card class="text-center" :header="cardHeader" header-class="font-weight-bold">
    <b-card-text>
      <p class="mb-0">{{ $t('datetime.timespan') }}</p>
      <h4 class="text-muted">{{ formattedTimespan }}</h4>
      <hr />
      <p class="mb-0">
        <span>{{ $t('timesheet.target-working-hours') }}</span>
        <span
          v-if="showInfoTooltips"
          class="ml-1"
          v-b-tooltip.right
          :title="$t('timesheet.tooltips.target-working-hours')"
        >
          <b-icon icon="question-circle" variant="secondary" scale="0.9" />
        </span>
      </p>
      <b-spinner v-if="targetWorkingMinutes === null" variant="secondary" />
      <h4 v-else class="text-muted">{{ formatMinutes(targetWorkingMinutes) }}</h4>
      <hr />
      <p class="mb-0">
        <span>{{ $t('timesheet.actual-working-hours') }}</span>
        <span
          v-if="showInfoTooltips"
          class="ml-1"
          v-b-tooltip.right
          :title="$t('timesheet.tooltips.actual-working-hours')"
        >
          <b-icon icon="question-circle" variant="secondary" scale="0.9" />
        </span>
      </p>
      <b-spinner v-if="actualWorkingMinutes === null" variant="secondary" />
      <h4 v-else class="text-muted">{{ formatMinutes(actualWorkingMinutes) }}</h4>
      <hr />
      <p class="mb-0">
        <span>{{ $t('timesheet.overtime') }}</span>
        <span v-if="showInfoTooltips" class="ml-1" v-b-tooltip.right :title="$t('timesheet.tooltips.overtime-month')">
          <b-icon icon="question-circle" variant="secondary" scale="0.9" />
        </span>
      </p>
      <b-spinner v-if="targetWorkingMinutes === null || actualWorkingMinutes === null" variant="secondary" />
      <h4 v-else :class="[colorFormatHours(timespanWorkingHoursSum)]">{{ timespanWorkingHoursSum }}</h4>
    </b-card-text>
  </b-card>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { TimesheetFunctionsMixin } from '@/components/timesheet/TimesheetFunctionsMixin'

export default {
  name: 'TimesheetOverviewCard',
  mixins: [ApiMixin, TimesheetFunctionsMixin],
  props: {
    userId: {
      required: true,
      type: Number
    },
    cardHeader: {
      required: true,
      type: String
    },
    timespanStart: {
      required: true,
      type: String
    },
    timespanEnd: {
      required: true,
      type: String
    },
    showInfoTooltips: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      targetWorkingMinutes: null,
      actualWorkingMinutes: null
    }
  },
  mounted() {
    this.fetchTargetWorkingMinutes()
    this.fetchActualWorkingMinutes()
  },
  computed: {
    timespanWorkingHoursSum() {
      return this.workingHoursSum(this.targetWorkingMinutes, this.actualWorkingMinutes)
    },
    formattedTimespan() {
      const startMonth = this.timespanStart.slice(5, 7)
      const endMonth = this.timespanEnd.slice(5, 7)
      if (this.timespanStart === this.timespanEnd) {
        return this.formatDate(this.timespanEnd).slice(0, -4)
      } else if (startMonth === endMonth) {
        return this.formatDate(this.timespanStart).slice(0, 3) + ' - ' + this.formatDate(this.timespanEnd).slice(0, -4)
      } else {
        return this.formatDate(this.timespanStart).slice(0, 6) + ' - ' + this.formatDate(this.timespanEnd).slice(0, -4)
      }
    }
  },
  methods: {
    fetchTargetWorkingMinutes() {
      let self = this
      this.getRequest(
        `/users/${this.userId}/timesheet/target_working_minutes?start=${this.timespanStart}&end=${this.timespanEnd}`,
        new RequestConfig().onSuccess(res => {
          self.targetWorkingMinutes = res.data
        })
      )
    },
    fetchActualWorkingMinutes() {
      let self = this
      this.getRequest(
        `/users/${this.userId}/timesheet/actual_working_minutes?start=${this.timespanStart}&end=${this.timespanEnd}`,
        new RequestConfig().onSuccess(res => {
          self.actualWorkingMinutes = res.data
        })
      )
    }
  }
}
</script>
