import store from '../store/index.js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'
import isoWeek from 'dayjs/plugin/isoWeek'
import isoWeekInYear from 'dayjs/plugin/isoWeeksInYear'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

// require day.js language files, english ('en') is included per default
require('dayjs/locale/de')

// register plugins
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(weekOfYear)
dayjs.extend(weekday)
dayjs.extend(isoWeek)
dayjs.extend(isoWeekInYear)
dayjs.extend(isLeapYear)
dayjs.extend(isSameOrBefore)

// Set default locale from user settings
const dayjsLocale = store.getters.getLocale ? store.getters.getLocale : 'en'
dayjs.locale(dayjsLocale)

// Set default timezone from app config
// BUT this gets only applied to dayjs.tz() command as described in https://github.com/iamkun/dayjs/issues/1227#issuecomment-900816717
const dayjsTimezone = store.getters.getConfig ? store.getters.getConfig.APP_TIMEZONE : 'Europe/Berlin'
dayjs.tz.setDefault(dayjsTimezone)

export const LocalDateTimeFormatter = {
  dateFormat: 'YYYY-MM-DD',
  localDateFormat: 'DD.MM.YYYY',
  timeFormat: 'HH:mm',
  localDateTimeFormat: 'DD.MM.YYYY HH:mm:ss',

  toLocalizedDay(localDate) {
    let parsedDate = dayjs(localDate)
    return parsedDate.isValid() ? parsedDate.format('dd DD.') : ''
  },

  toDate(localDateTime) {
    let parsedDateTime = dayjs(localDateTime)
    return parsedDateTime.isValid() ? parsedDateTime.format(this.dateFormat) : ''
  },
  toTime(localDateTime) {
    let parsedDateTime = dayjs(localDateTime)
    return parsedDateTime.isValid() ? parsedDateTime.format(this.timeFormat) : ''
  },
  durationInMinutesToTime(durationInMinutes) {
    // dayjs will format duration that extend one day such that we "loose" the day
    // instead of a desired value like 25:20 it will format to 01:20
    // So for values larger than one day we'll do it "by hand"
    if (durationInMinutes >= 1440) {
      let asHours = dayjs.duration(durationInMinutes, 'minutes').asHours()
      let minutes = dayjs.duration(durationInMinutes, 'minutes').minutes()
      let parsedMinutes = minutes < 10 ? '0' + Math.floor(minutes) : Math.floor(minutes)
      let parsedHours = asHours < 10 ? '0' + Math.floor(asHours) : Math.floor(asHours)
      return parsedHours + ':' + parsedMinutes
    } else {
      return dayjs.duration(durationInMinutes, 'minutes').format(this.timeFormat)
    }
  },

  dayjsDurationToTimeFormat(dayjsDuration) {
    return dayjsDuration.format(this.timeFormat)
  },

  toDateTime(date, time) {
    let dateString = this.toDate(date)
    if (dateString !== '' && time.match('^\\d\\d:\\d\\d$')) {
      return dateString + 'T' + time + ':00'
    } else {
      return ''
    }
  },

  toTimestampFromUnix(unixTimeInSeconds) {
    return dayjs.unix(unixTimeInSeconds).tz(dayjsTimezone).format(this.localDateTimeFormat)
  },

  formatLocaleDate(localDateTime) {
    if (localDateTime) {
      return dayjs(localDateTime).format(this.localDateFormat)
    } else {
      return ''
    }
  },

  durationSinceStartingTimeWithBreakTime(startingTime, breakTime) {
    return dayjs.duration(dayjs().diff(startingTime)).subtract(dayjs.duration(breakTime, 'minutes'))
  },

  transformSecondsToDays(valueInMinutes, hoursPerDay) {
    return Math.round((valueInMinutes / 60 / hoursPerDay) * 10) / 10
  },

  isWeekendDay(localDate) {
    let weekdayNum = dayjs(localDate).day()
    return weekdayNum === 6 || weekdayNum === 0
  }
}

export const LocalDate = {
  getISOTimestamp() {
    return dayjs().toISOString()
  },
  todayAsDayjs() {
    return dayjs()
  },
  today() {
    return LocalDateTimeFormatter.toDate(dayjs())
  },
  yesterday() {
    return LocalDateTimeFormatter.toDate(dayjs().subtract(1, 'day'))
  },
  currentWeek() {
    let today = dayjs().toString()
    return Number(dayjs(today).week())
  },
  currentMonth() {
    return dayjs().month() + 1
  },
  currentYear() {
    return Number(dayjs().format('YYYY'))
  },
  nextYear() {
    return Number(dayjs().add(1, 'year').format('YYYY'))
  },
  parseMonth(month) {
    return dayjs.months()[month - 1]
  },
  lastYear() {
    return Number(dayjs().subtract(1, 'year').format('YYYY'))
  },
  mondayInCurrentWeek() {
    let monday = 1
    let dayOfWeek = dayjs().day()
    return LocalDateTimeFormatter.toDate(dayjs().subtract(-(monday - dayOfWeek), 'day'))
  },
  mondayInLastWeek() {
    let mondayInCurrentWeek = dayjs(this.mondayInCurrentWeek(), LocalDateTimeFormatter.dateFormat)
    return LocalDateTimeFormatter.toDate(mondayInCurrentWeek.subtract(7, 'day'))
  },
  fridayInCurrentWeek() {
    let friday = 5
    let dayOfWeek = dayjs().day()
    return LocalDateTimeFormatter.toDate(dayjs().add(friday - dayOfWeek, 'day'))
  },
  fridayInLastWeek() {
    let fridayInCurrentWeek = dayjs(this.fridayInCurrentWeek(), LocalDateTimeFormatter.dateFormat)
    return LocalDateTimeFormatter.toDate(fridayInCurrentWeek.subtract(7, 'day'))
  },
  firstDayOfCurrentMonth() {
    return LocalDateTimeFormatter.toDate(dayjs().date(1))
  },
  firstDayOfLastMonth() {
    let firstDayOfCurrentMonth = dayjs(this.firstDayOfCurrentMonth(), LocalDateTimeFormatter.dateFormat)
    let daysInLastMonth = firstDayOfCurrentMonth.subtract(1, 'day').daysInMonth()
    return LocalDateTimeFormatter.toDate(firstDayOfCurrentMonth.subtract(daysInLastMonth, 'day'))
  },
  lastDayOfCurrentMonth() {
    let today = dayjs()
    return LocalDateTimeFormatter.toDate(today.date(today.daysInMonth()))
  },
  lastDayOfLastMonth() {
    let lastDayOfCurrentMonth = dayjs(this.lastDayOfCurrentMonth(), LocalDateTimeFormatter.dateFormat)
    return LocalDateTimeFormatter.toDate(lastDayOfCurrentMonth.subtract(dayjs().daysInMonth(), 'day'))
  },
  lastDayOfMonth(year, month) {
    let parsedMonth = dayjs(`${year}-${month}`, 'YYYY-MM')
    return LocalDateTimeFormatter.toDate(parsedMonth.date(parsedMonth.daysInMonth()))
  },
  weeksInYear(year) {
    let startOfYear = dayjs().year(year).startOf('year')
    let numOfWeeksInYear = dayjs(startOfYear).isoWeeksInYear()
    let weeksOfYear = Array(numOfWeeksInYear)
    for (let i = 1; i <= numOfWeeksInYear; i++) {
      let startOfWeek = dayjs().year(year).isoWeek(i).isoWeekday(1).format('DD. MMM')
      let endOfWeek = dayjs().year(year).isoWeek(i).isoWeekday(7).format('DD. MMM')
      let object = Object.assign({}, { value: i, text: 'KW ' + i + ' (' + startOfWeek + ' - ' + endOfWeek + ')' })
      weeksOfYear[i - 1] = object
    }
    return weeksOfYear
  },
  remainingWeeksInCurrentYear(year, currentWeek) {
    let startOfYear = dayjs().year(parseInt(year)).startOf('year')
    let numOfWeeksInYear = dayjs(startOfYear).isoWeeksInYear()
    let weeksOfYear = Array(numOfWeeksInYear)
    for (let i = currentWeek; i <= numOfWeeksInYear; i++) {
      let startOfWeek = dayjs().year(parseInt(year)).isoWeek(i).isoWeekday(1).format('DD. MMM')
      let endOfWeek = dayjs().year(parseInt(year)).isoWeek(i).isoWeekday(7).format('DD. MMM')
      let object = Object.assign({}, { value: i, text: 'KW ' + i + ' (' + startOfWeek + ' - ' + endOfWeek + ')' })
      weeksOfYear[i - 1] = object
    }
    return weeksOfYear
  }
}

export const StaticDates = {
  yearListAsArray(minYear, maxYear) {
    let iterFromFirstYear = minYear
    let yearList = []
    while (iterFromFirstYear <= maxYear) {
      yearList.push(iterFromFirstYear)
      iterFromFirstYear++
    }
    return yearList
  },
  yearListSinceAsArray(minYear) {
    return this.yearListAsArray(minYear, LocalDate.currentYear())
  },
  yearListAsSelectArray(minYear, maxYear) {
    let yearList = []
    this.yearListAsArray(minYear, maxYear).forEach(function (year) {
      yearList.push({ value: year, text: year })
    })
    return yearList
  },
  yearListSinceAsSelectArray(minYear) {
    return this.yearListAsSelectArray(minYear, LocalDate.currentYear())
  },
  monthListAsSelectArray() {
    const monthOptions = []
    const months = dayjs.months()
    months.map((month, index) => {
      monthOptions.push({
        value: index + 1,
        text: month
      })
    })
    return monthOptions
  }
}
