<template>
  <b-card-group deck class="text-center" :header="$t('timesheet.total')" header-class="font-weight-bold">
    <b-card :header="$t('timesheet.flextime-balance-last-year')" header-class="font-weight-bold">
      <b-spinner v-if="flextimeInfo.lastYear === null" variant="secondary" />
      <h4 v-else :class="[colorFormatMinutes(flextimeInfo.lastYear)]">
        {{ formatPossiblyNegativeMinutes(flextimeInfo.lastYear) }}
      </h4>
    </b-card>
    <b-card :header="$t('timesheet.flextime-balance-up-to-today')" header-class="font-weight-bold">
      <b-spinner v-if="flextimeJustThisYear === null" variant="secondary" />
      <h4 v-else :class="[colorFormatMinutes(flextimeJustThisYear)]">
        {{ formatPossiblyNegativeMinutes(flextimeJustThisYear) }}
      </h4>
    </b-card>
    <b-card
      v-if="flextimeCorrections === null || flextimeCorrections.length > 0"
      :header="$t('timesheet.flextime-corrections')"
      header-class="font-weight-bold"
    >
      <b-spinner v-if="flextimeCorrections === null" variant="secondary" />
      <h4 v-else :class="[colorFormatMinutes(totalFlextimeCorrections)]">
        {{ formatPossiblyNegativeMinutes(totalFlextimeCorrections) }}
      </h4>
    </b-card>
    <b-card :header="$t('timesheet.flextime-balance-total')" header-class="font-weight-bold">
      <b-spinner v-if="flextimeInfo.currentYear === null" variant="secondary" />
      <h4 v-else :class="[colorFormatMinutes(flextimeInfo.currentYear)]">
        {{ formatPossiblyNegativeMinutes(flextimeInfo.currentYear) }}
      </h4>
    </b-card>
  </b-card-group>
</template>

<script>
import { TimesheetFunctionsMixin } from '@/components/timesheet/TimesheetFunctionsMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'

export default {
  name: 'TimesheetFlextimeOverview',
  data() {
    return {
      flextimeInfo: {
        lastYear: null,
        currentYear: null
      },
      flextimeCorrections: null
    }
  },
  mixins: [TimesheetFunctionsMixin, NotificationMixin, ApiMixin],
  props: {
    userId: {
      required: true,
      type: Number
    }
  },
  mounted() {
    this.loadFlextimeInfoLastYear()
    this.loadFlextimeInfoCurrentYear()
    this.loadFlextimeCorrections()
  },
  computed: {
    totalFlextimeCorrections() {
      let total = 0
      if (this.flextimeCorrections === null) {
        return 0
      }
      for (let flextimeCorrection of this.flextimeCorrections) {
        total += flextimeCorrection.durationInMinutes
      }
      return total
    },
    flextimeJustThisYear() {
      if (
        this.flextimeInfo.currentYear === null ||
        this.flextimeInfo.lastYear === null ||
        this.flextimeCorrections === null
      ) {
        return null
      }
      return this.flextimeInfo.currentYear - this.flextimeInfo.lastYear - this.totalFlextimeCorrections
    }
  },
  methods: {
    updateFlextimeInfoCurrentYear(flextimeMinutes) {
      this.flextimeInfo.currentYear = flextimeMinutes
    },
    loadFlextimeInfoLastYear() {
      let self = this
      this.getRequest(
        `/users/${this.userId}/flextime_infos?year=${this.dates.lastYear}`,
        new RequestConfig().onSuccess(res => {
          self.flextimeInfo.lastYear = res.data.flextimeBalanceOverall
        })
      )
    },
    loadFlextimeInfoCurrentYear() {
      let self = this
      this.getRequest(
        `/users/${this.userId}/flextime_infos?year=${this.dates.currentYear}&limitToday=true`,
        new RequestConfig().onSuccess(res => {
          self.flextimeInfo.currentYear = res.data.flextimeBalanceOverall
        })
      )
    },
    formatPossiblyNegativeMinutes(minutes) {
      let isNegative = minutes < 0
      let minutesPositive = Math.abs(minutes)
      return (isNegative ? '-' : '') + this.formatMinutes(minutesPositive)
    },
    loadFlextimeCorrections() {
      let self = this
      this.getRequest(
        `/users/${this.userId}/flextime_corrections?year=${this.dates.currentYear}`,
        new RequestConfig().onSuccess(res => {
          self.flextimeCorrections = res.data
        })
      )
    }
  }
}
</script>
