<template>
  <div>
    <b-modal
      id="edit-correction-modal"
      v-model="modalControl.editCorrections.show"
      size="lg"
      centered
      :title="editCorrectionModalTitle"
      :ok-title="$t('general.save')"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      @ok="editCorrectionModalOk"
      :ok-disabled="editCorrectionModalDisabled"
      @hidden="resetEditCorrectionModal"
    >
      <b-form>
        <b-form-group
          class="mt-4 mb-2"
          label-for="editCorrectionDate"
          label-cols="6"
          label-cols-lg="4"
          :label="$t('timetracking.date')"
        >
          <Datepicker
            id="date-input"
            v-model="modalControl.editCorrections.date"
            :placeholder="this.$t('timetracking.date')"
            :locale="this.$store.getters.getLocale"
            :reset-button="false"
          />
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-for="editCorrectionMinutes"
          label-cols="6"
          label-cols-lg="4"
          :label="$t('timesheet.correction-in-minutes')"
        >
          <b-form-input
            id="editCorrectionMinutes"
            type="number"
            v-model="modalControl.editCorrections.correctionMinutes"
          />
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-for="editComment"
          label-cols="6"
          label-cols-lg="4"
          :label="$t('general.comment')"
        >
          <b-form-input id="editComment" type="text" v-model="modalControl.editCorrections.comment" />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="delete-correction-modal"
      v-model="modalControl.deleteCorrections.show"
      size="lg"
      centered
      :title="$t('timesheet.delete-correction')"
      :ok-title="$t('general.delete')"
      ok-variant="danger"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      @ok="deleteCorrectionModalOk"
      @hidden="resetDeleteCorrectionModal"
    >
      {{ $t('timesheet.delete-correction-text') }}
    </b-modal>

    <b-container fluid id="headContainer" class="p-0 mb-3">
      <b-row align-h="between" align-v="end">
        <b-col>
          <b-button v-if="showActions" @click="openEditCorrectionModal(null)" variant="info">{{
            $t('timesheet.create-flextime-correction')
          }}</b-button>
        </b-col>
        <b-col cols="2">
          <b-select :options="yearOptionsWithAllOption" v-model="filter.year" @change="loadFlextimeCorrections()" />
        </b-col>
      </b-row>
    </b-container>

    <b-table
      small
      bordered
      head-variant="light"
      :fields="tableFields"
      :items="flextimeCorrections"
      sort-by="date"
      :busy="tableBusy"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-2" />
          <strong>{{ $t('general.table.loading') }}</strong>
        </div>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button @click="openEditCorrectionModal(row.item)" size="xs" class="mr-1" variant="outline-secondary">
          <b-icon icon="pencil" aria-hidden="true" />
        </b-button>
        <b-button @click="openDeleteCorrectionModal(row.item)" size="xs" variant="outline-danger">
          <b-icon icon="trash" aria-hidden="true" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { TimesheetFunctionsMixin } from '@/components/timesheet/TimesheetFunctionsMixin'
import { LocalDate, StaticDates } from '@/util/LocalDateTimeFormatter'
import Datepicker from '@/components/GoldflamDatepicker'
import { EventBus } from '@/eventBus'

export default {
  name: 'TimesheetFlextimeCorrectionManagement',
  props: {
    userId: {
      required: false,
      type: Number,
      default: null
    },
    showActions: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  mixins: [ApiMixin, NotificationMixin, TimesheetFunctionsMixin],
  components: { Datepicker },
  data() {
    return {
      tableBusy: false,
      flextimeCorrections: [],
      yearOptions: [],
      filter: {
        year: null
      },
      modalControl: {
        editCorrections: {
          show: false,
          edit: false,
          date: null,
          correctionMinutes: 0,
          comment: '',
          correctionId: null
        },
        deleteCorrections: {
          show: false,
          correctionId: null
        }
      },
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark'
    }
  },
  mounted() {
    this.filter.year = LocalDate.currentYear()
    this.loadFlextimeCorrections()
    this.yearOptions = StaticDates.yearListAsSelectArray(
      this.$store.getters.getConfig.APP_FIRST_YEAR,
      LocalDate.currentYear()
    )
  },
  computed: {
    tableFields() {
      let baseTableFields = [
        {
          key: 'date',
          label: this.$t('timetracking.date'),
          class: 'text-center',
          formatter: 'formatDate',
          sortable: true
        },
        {
          key: 'durationInMinutes',
          label: this.$t('timesheet.flextime-correction'),
          class: 'text-center',
          formatter: 'formatPossiblyNegativeMinutes',
          sortable: false
        },
        {
          key: 'comment',
          label: this.$t('general.comment'),
          class: 'text-center',
          sortable: false
        },
        {
          key: 'authorizedBy',
          label: this.$t('timesheet.authorized-by'),
          class: 'text-center',
          formatter: 'formatUserToFullName',
          sortable: false
        }
      ]
      if (this.showActions) {
        baseTableFields.push({
          key: 'actions',
          label: this.$t('general.table.actions'),
          class: 'text-center',
          sortable: false
        })
      }
      return baseTableFields
    },
    yearOptionsWithAllOption() {
      let options = [...this.yearOptions]
      options.push({ value: null, text: this.$t('vacation.all-years') })
      return options
    },
    editCorrectionModalTitle() {
      return this.modalControl.editCorrections.edit
        ? this.$t('timesheet.edit-flextime-correction')
        : this.$t('timesheet.create-flextime-correction')
    },
    editCorrectionModalDisabled() {
      return (
        this.modalControl.editCorrections.correctionMinutes === null ||
        this.modalControl.editCorrections.correctionMinutes === '' ||
        this.modalControl.editCorrections.date === null ||
        this.modalControl.editCorrections.date === '' ||
        this.modalControl.editCorrections.comment === null ||
        this.modalControl.editCorrections.comment === ''
      )
    }
  },
  methods: {
    formatPossiblyNegativeMinutes(minutes) {
      let isNegative = minutes < 0
      let minutesPositive = Math.abs(minutes)
      return (isNegative ? '-' : '') + this.formatMinutes(minutesPositive)
    },
    loadFlextimeCorrections() {
      let url = `/users/${this.userId}/flextime_corrections`
      if (this.filter.year !== null) {
        url += `?year=${this.filter.year}`
      }
      let self = this
      self.tableBusy = true
      this.getRequest(
        url,
        new RequestConfig().onSuccess(res => {
          self.flextimeCorrections = res.data
          self.tableBusy = false
        })
      )
    },
    formatUserToFullName(user) {
      return user !== null ? user.fullName : ''
    },
    openEditCorrectionModal(correction) {
      this.modalControl.editCorrections.show = true
      if (correction == null) {
        this.resetEditCorrectionModal()
      } else {
        this.modalControl.editCorrections.edit = true
        this.modalControl.editCorrections.date = correction.date
        this.modalControl.editCorrections.correctionMinutes = correction.durationInMinutes
        this.modalControl.editCorrections.comment = correction.comment
        this.modalControl.editCorrections.correctionId = correction.id
      }
    },
    editCorrectionModalOk() {
      let body = {
        date: this.modalControl.editCorrections.date,
        durationInMinutes: this.modalControl.editCorrections.correctionMinutes,
        comment: this.modalControl.editCorrections.comment
      }
      let url = `/users/${this.userId}/flextime_corrections`
      if (this.modalControl.editCorrections.edit) {
        url += `/${this.modalControl.editCorrections.correctionId}`
      }
      let self = this
      this.postRequest(
        url,
        body,
        new RequestConfig().onSuccess(() => {
          self.emitUpdate()
          self.flextimeCorrections.length = 0
          self.loadFlextimeCorrections()
        })
      )
    },
    resetEditCorrectionModal() {
      this.modalControl.editCorrections.edit = false
      this.modalControl.editCorrections.date = null
      this.modalControl.editCorrections.correctionMinutes = 0
      this.modalControl.editCorrections.comment = ''
      this.modalControl.editCorrections.correctionId = null
    },
    openDeleteCorrectionModal(correction) {
      this.modalControl.deleteCorrections.show = true
      this.modalControl.deleteCorrections.correctionId = correction.id
    },
    deleteCorrectionModalOk() {
      let self = this
      this.deleteRequest(
        `/users/${this.userId}/flextime_corrections/${this.modalControl.deleteCorrections.correctionId}`,
        new RequestConfig().onSuccess(() => {
          self.emitUpdate()
          self.flextimeCorrections.length = 0
          self.loadFlextimeCorrections()
        })
      )
    },
    resetDeleteCorrectionModal() {
      this.modalControl.deleteCorrections.correctionId = null
    },
    emitUpdate() {
      EventBus.$emit('flextime-corrections-updated', LocalDate.getISOTimestamp())
    }
  }
}
</script>
